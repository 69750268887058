import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  firstName: "",
  lastName: "",
  mobile: "",
  code: "",
  email: "",
  dateofBirth: "",
  gender: "",
  teamSize: "",
  leadId: null,
};

export const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    leadId: (state, action) => {
      state.leadId = action.payload;
    },
    firstName: (state, action) => {
      console.log("state and action :", state, action);
      state.firstName = action.payload;
    },
    lastName: (state, action) => {
      state.lastName = action.payload;
    },
    mobile: (state, action) => {
      state.mobile = action.payload;
    },
    code: (state, action) => {
      state.code = action.payload;
    },
    email: (state, action) => {
      state.email = action.payload;
    },
    dateofBirth: (state, action) => {
      state.dateofBirth = action.payload;
    },
    gender: (state, action) => {
      state.gender = action.payload;
    },
    teamSize: (state, action) => {
      state.teamSize = action.payload;
    },
  },
});

export const {
  firstName,
  lastName,
  mobile,
  code,
  email,
  dateofBirth,
  gender,
  teamSize,
  leadId,
} = userDataSlice.actions;
export default userDataSlice.reducer;
