import { DatePicker, Radio } from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import vwLogo from "../../assets/images/vw-logo.png";
import { useDispatch, useSelector } from "react-redux";
import { identityDetailsService } from "../../services/detailsApiServices";
import { dateofBirth, gender } from "../../features/userData/userDataSlice";
import { toast } from "react-toastify";
import moment from "moment";
import {loader, onkeyPressHandler} from '../../utilies/Utilies';

const Details = () => {
	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	const years = new Date().getFullYear();

	const navigate = useNavigate();
	const [dob, setDob] = useState(null);
	const [userGender, setUserGender] = useState("");
	const [errorMsg, setErrorMsg] = useState(false);
	const [day, setDay] = useState();
	const [month, setMonth] = useState();
	const [year, setYear] = useState();
    const [loading, setLoading] = useState(false);
    const [activeId, setActiveId] = useState('submitBtn')

	const dispatch = useDispatch();
	const userData = useSelector((state) => state.userData);
	// console.log(userData);

	const genderHandler = (e) => {
		setUserGender(e.target.value);
	};
	// console.log(gender);

	const submitHandler = async () => {
		try {
			if (formValidation()) {
				setLoading(true)
				let data = {
					leadId: userData?.leadId,
					leadDob: `${year}-${month}-${day}`,
					leadGender: userGender,
					journeyIndex: 5,
				};

				const response = await identityDetailsService(data);
				// console.log("identity Details Response:", response);
				if (response?.status === "success" &&response?.data?.addedIdentityDetails) {
					 setLoading(false)
					 dispatch(dateofBirth(response?.data?.dbResponse?.dob));
					dispatch(gender(response?.data?.dbResponse?.gender));
					navigate("/teamStrength");
				} else {
					setLoading(false)
					toast.error("something went wrong");

				}
			}
		} catch (error) {
			console.log(error);
		}
	};



	const getUserDetails = () => {
		setDob(userData?.dateofBirth);
         let userDateOfBirth = userData?.dateofBirth;
		setDay(userDateOfBirth? moment(userDateOfBirth).format("D") :moment(new Date()).format("D") );
		setMonth(userDateOfBirth ? moment(userDateOfBirth).format("MMMM") :moment(new Date()).format("MMMM") );
		setYear(userDateOfBirth ? moment(userDateOfBirth).format("YYYY") : moment(new Date()).format("YYYY") );
		setUserGender(userData?.gender);
	};



	// console.log(dob);
	// console.log(day);
	// console.log(month);
	// console.log(year);
	// console.log(userGender);

	useEffect(() => {
		getUserDetails();
	}, [userData]);

	const formValidation = () => {
		let isValid = true;
		if (!userGender) {
			isValid = false;
			setErrorMsg(true);
		}
		if (!day) {
			isValid = false;
			setErrorMsg(true);
		}
		if (!month) {
			isValid = false;
			setErrorMsg(true);
		}
		if (!year) {
			isValid = false;
			setErrorMsg(true);
		}
		return isValid;
	};

	

	return (
		<div>
			<div className="vwContainer">
				<div className="vwHeader">
					<div className="vwLogo">
						<img src={vwLogo} width="200px" alt="voltuswave logo" srcSet="" />
					</div>
				</div>
				<div className="vwContent">
					<div className="vwDetailsHead">
						<strong>Identity Details</strong>
						<br />
					</div>
					<div className="row ">
						<div className="col">
							<label>Date Of Birth *</label>
							<div className="dobContainer">
								<div>
									<select
										className="selectOption"
										onChange={(e) => {
											setDay(e.target.value);
										}}
										value={day}
									>
										{/* <option disabled selected hidden>Day</option> */}
										{[...Array(31)].map((item, id) => (
											<option value={id + 1} key={id}>
												{id + 1}
											</option>
										))}
									</select>
									<div>
										{errorMsg && !day && (
											<span className="errorMsg">day is required</span>
										)}
									</div>
								</div>
								<div>
									<select
										className="selectOption"
										onChange={(e) => {
											setMonth(e.target.value);
										}}
										value={month}
									>
										{/* <option disabled>Month</option> */}
										{months.map((item, id) => {
											return (
												<option value={item} key={id}>
													{" "}
													{item}
												</option>
											);
										})}
									</select>
									<div>
										{errorMsg && !month && (
											<span className="errorMsg">month is required</span>
										)}
									</div>
								</div>
								<div>
									<select
										className="selectOption"
										onChange={(e) => {
											setYear(e.target.value);
										}}
										value={year}
									>
										{/* <option disabled>Year</option> */}
										{[...Array(100)].map((item, id) => {
											return (
												<option value={years - id} key={id}>
													{years - id}
												</option>
											);
										})}
									</select>
									<div>
										{errorMsg && !year && (
											<span className="errorMsg">year is required</span>
										)}
									</div>
								</div>
							</div>
							{/* <label htmlFor="">Date of Birth</label><br />
              <input type="date"
                value={dob}
                onChange={(e) => { setDob(e.target.value) }}
              />
              <div>
              {errorMsg && !dob && <span className='errorMsg'>date of birth is required</span>}
              </div> */}
						</div>
					</div>
					<div className="row radioFieldContainer">
						<div className="col">
							<label>Gender *</label>
							<br />
							<div className="radioOptions" >
								{/* <div className="radio"  > */}
								<label htmlFor="Male">
									<input
										type="radio"
										id="Male"
										value="Male"
										checked={userGender === "Male"}
										onChange={genderHandler}
										onKeyPress={(event)=>onkeyPressHandler(event,activeId)}
									/>{" "}
									Male </label>
								{/* </div> */}
								{/* <div className="radio"> */}
								<label htmlFor="Female">
									<input
										type="radio"
										id="Female"
										value="Female"
										checked={userGender === "Female"}
										onChange={genderHandler}
										onKeyPress={(event)=>onkeyPressHandler(event,activeId)}

									/>{" "}
									Female</label>
								{/* </div> */}
								{/* <div className="radio"> */}
									<label htmlFor="Others">
									<input
										type="radio"
										id="Others"
										value="Others"
										checked={userGender === "Others"}
										onChange={genderHandler}
										onKeyPress={(event)=>onkeyPressHandler(event,activeId)}

									/>{" "}
									Others</label>
								{/* </div> */}
							</div>
							<div>
								{errorMsg && !userGender && (
									<span className="errorMsg">Please select gender</span>
								)}
							</div>
						</div>
					</div>
					<div className="buttonContainer">
						<button id="submitBtn" className="submitBtn" onFocus={submitHandler}>
							{loading ? loader(loading) :  "continue"}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Details;
