import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import vwLogo from "../../assets/images/vw-logo.png";
import viewPswd from "../../assets/images/show.png";
import hidePswd from "../../assets/images/hide.png";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import Information from "../../assets/images/information.png";
import { Tooltip } from "antd";
import { createPasswordService } from "../../services/detailsApiServices";
import { toast } from "react-toastify";
import { loader } from "../../utilies/Utilies";
import { firstName } from "../../features/userData/userDataSlice";
import axios from "axios";

const CreatePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);
  // console.log(userData);

  const domainName = window.location.host;
  const subdomain = window.location.host.split(".")[0];
  const domainURL = window.location.host.split(".")[1];
  const topLevelDomain = window.location.host.split(".")[2];

  const domain = `${domainURL}.${topLevelDomain}`;

  const appId = "b2e1a6ca-d675-4d36-94bd-242d7d6cbecb";
  console.log("query params :", appId);

  //   console.log(`logging hostName :`,domainName)
  //   console.log(`logging subdomain :`,subdomain)

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [orgName, setOrgName] = useState("");
  const [subDomain, setSubDomain] = useState("");
  const [showPswd, setShowPswd] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);
  const [check, setCheck] = useState(false);
  const [passwordCheck, setPasswordCheck] = useState(false);
  const [strongPswd, setStrongPswd] = useState(false);
  const [showConfirmPswd, setShowConfirmPswd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDomain, setIsDomain] = useState(false);

  const recaptchaHandler = (value) => {
    console.log("recaptcha value:", value);
    setCaptcha(value);
  };
  console.log("captcha value ===", captcha);

  const submitHandler = async () => {
    try {
      if (formValidation() && password === confirmPassword) {
        setLoading(true);

        let data = {
          leadId: userData?.leadId,
          leadOrganisation: orgName,
          leadPassword: confirmPassword,
          leadSubdomainCode: subDomain,
          captcha: captcha,
          journeyIndex: 9,
        };

        const response = await createPasswordService(data);
        // console.log(response);
        if (
          response?.status === "success" &&
          response?.data?.addedIdentityDetails
        ) {
          let ontherData = {
            RegNo: 20001,
            authenticationType: "Default",
            countryCode: "",
            deviceId: domain, //domain or "localhost"
            domainName: domain,
            domainUrl: domain,
            email: userData?.email,
            recaptcha: null,
            firstName: userData?.firstName,
            lastName: userData?.lastName,
            mobileNumber: "",
            name: userData?.firstName + " " + userData?.lastName,
            orgCode: subDomain,
            orgName: orgName,
            orgJoinId: null,
            password: confirmPassword,
            personProfilePicture: null,
            profile: null,
            signupOption: "email",
            socialConnectionName: null,
            source: "Web",
            subDomainCode: subdomain ? subdomain : "cloud",
            solutionId: null,
          };

          axios
            .post("https://cloud.voltusone.com/onboardingSignup", ontherData, {
              headers: { "Content-Type": "application/json" },
            })
            // {
            // 	mode: 'no-cors',
            // 	method: 'POST',
            // 	headers: {
            // 			"Content-Type": "application/json",
            // 			"Access-Control-Allow-Origin":  "*",
            // 			"Access-Control-Allow-Methods": "POST",
            // 			"Access-Control-Allow-Headers": "Content-Type, Authorization"
            // 		}
            // 		}
            // ).

            // axios
            //   .post(
            //     `http://172.16.10.117:1337/onboardingSignup?appId=${appId}`,
            //     ontherData
            //   )
            .then((response) => {
              console.log("otherData response:", response);
              if (
                response?.status === 200 &&
                response?.data?.check &&
                response?.data?.success
              ) {
                setLoading(false);
                // console.log("redirect url:",response?.data?.data?.emailRedirectionLink);
                window.location.href = `${response?.data?.data?.emailRedirectionLink}`;
              } else {
                setLoading(false);
                toast.error("something went wrong");
              }
            })
            .catch((error) => {
              console.log(error);
            });
          setLoading(false);
          // toast.success('data submitted succesfully')
        } else {
          setLoading(false);
          toast.error("something went wrong");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDetails = () => {
    setEmail(userData?.email);
  };
  // console.log("check :", check);

  useEffect(() => {
    getDetails();
  }, [userData]);

  const passwordMatch = () => {
    if (!(password === confirmPassword)) {
      setPasswordCheck(true);
    } else {
      setPasswordCheck(false);
    }
  };

  const strongPassword = () => {
    passwordMatch();
    if (!password.match(strongRegex)) {
      setStrongPswd(true);
    } else {
      setStrongPswd(false);
    }
  };

  // function for domain checking:aws s3 sync build s3:/
  const domainChecking = () => {
    try {
      let data = {
        domainName: `${domainURL}.${topLevelDomain}`,
        orgCode: subDomain,
      };
      axios
        .post(
          `https://cloud.voltusone.com/isOrgCodeExists`,
          data,
          // axios.post(`http://172.16.114.147:1337/isOrgCodeExists`, data ,
          { headers: { "Content-Type": "application/json" } }
        )
        .then((response) => {
          console.log("domain exist response", response);
          if (response?.status === 200 && response?.data?.available) {
            // true means -> the domain is allreday exist
            setIsDomain(true);
          } else {
            setIsDomain(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {}
  };

  // regular expressions :
  let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  var strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  const passwordText =
    "Password should be at least 1 capital 1 small 1 number 1 special character and include 8 characters";

  const formValidation = () => {
    let isValid = true;
    if (!email) {
      isValid = false;
      setErrorMsg(true);
    }
    if (!password) {
      isValid = false;
      setErrorMsg(true);
    }
    if (!confirmPassword) {
      isValid = false;
      setErrorMsg(true);
    }
    if (!orgName) {
      isValid = false;
      setErrorMsg(true);
    }
    if (!subDomain) {
      isValid = false;
      setErrorMsg(true);
    }
    if (check === false) {
      isValid = false;
      setErrorMsg(true);
    }
    return isValid;
  };
  // console.log(process.env.REACT_APP_ACCOUNT_SUB_DOMAIN);
  return (
    <div>
      <div className="vwContainer">
        <div className="vwHeader">
          <div className="vwLogo">
            <img src={vwLogo} width="200px" alt="voltuswave logo" srcSet="" />
          </div>
        </div>
        <div className="vwContent">
          <div className="vwCreatePasswordHead">
            <strong>Create a Password</strong>
            <br />
            <small className="text">Account Password</small>
          </div>
          <div className="row inputField">
            <div className="col">
              <label>Email *</label>
              <div>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  disabled
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <div>
                  {(errorMsg && !email && (
                    <span className="errorMsg">Email is required</span>
                  )) ||
                    (errorMsg && !email.match(regex) && (
                      <span className="errorMsg">Please enter valid email</span>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row inputField">
            <div className="col">
              <label>
                Password *{" "}
                <Tooltip
                  placement="rightBottom"
                  title={strongPswd && passwordText}
                >
                  <img
                    src={Information}
                    className="informIcon"
                    height="13px"
                    srcSet=""
                  />
                </Tooltip>
              </label>
              <div className="passwordContainer">
                <input
                  type={showPswd ? "text" : "password"}
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  onBlur={strongPassword}
                />
                <img
                  src={showPswd ? viewPswd : hidePswd}
                  srcSet=""
                  onClick={() => {
                    setShowPswd(!showPswd);
                  }}
                  height="20px"
                  className="eyeIcon"
                />
                <div>
                  {(errorMsg && !password && (
                    <span className="errorMsg">Password is required</span>
                  )) ||
                    (strongPswd && (
                      <span className="errorMsg">Required strong password</span>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row inputField">
            <div className="col">
              <label>Confirm Password *</label>
              <div className="passwordContainer">
                <input
                  type={showConfirmPswd ? "text" : "password"}
                  className="form-control"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                  onBlur={passwordMatch}
                />
                <img
                  src={showConfirmPswd ? viewPswd : hidePswd}
                  srcSet=""
                  onClick={() => {
                    setShowConfirmPswd(!showConfirmPswd);
                  }}
                  height="20px"
                  className="eyeIcon"
                />
                <div>
                  {(errorMsg && !confirmPassword && (
                    <span className="errorMsg">
                      Confirm password is required
                    </span>
                  )) ||
                    (passwordCheck && (
                      <span className="errorMsg">
                        Confirm password is not matched
                      </span>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row inputField">
            <div className="col">
              <label>Organization Name *</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  name="orgName"
                  value={orgName}
                  onChange={(e) => {
                    setOrgName(e.target.value);
                  }}
                />
                <div>
                  {errorMsg && !orgName && (
                    <span className="errorMsg">
                      Organization name is required
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row inputField">
            <div className="col">
              <label>
                Account Subdomain *{" "}
                <img
                  src={Information}
                  className="informIcon"
                  height="13px"
                  srcSet=""
                />
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  maxLength="10"
                  value={subDomain}
                  onChange={(e) => {
                    setSubDomain(e.target.value);
                  }}
                  name="subDomain"
                  style={{ outline: "unset" }}
                  onBlur={domainChecking}
                />
                <span className="subDomain">
                  {process.env.REACT_APP_ACCOUNT_SUB_DOMAIN}
                </span>
              </div>
              <div>
                {errorMsg && !subDomain && (
                  <span className="errorMsg">Subdomain is required</span>
                )}
                {isDomain && (
                  <span className="errorMsg">Domain is alreday exists</span>
                )}
              </div>
            </div>
          </div>
          <div className="row inputField">
            <div className="col">
              <label>Recaptcha Validation *</label>
              <ReCAPTCHA
                sitekey="6Ldb3l8UAAAAAO8xBJ1q_GLfDn2kkcQNBCqC02GU"
                onChange={recaptchaHandler}
              />
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <input
              type="checkbox"
              value="check"
              onChange={() => {
                setCheck(!check);
              }}
              name=""
              id=""
            />
            <span>
              {" "}
              I agree to the <a>Terms & Conditions</a> and <a>Privacy Policy</a>
            </span>
            {/* <div>
                {errorMsg && check === false && <span className='errorMsg'>please accept terms&conditions</span>}
                </div> */}
          </div>
          <div className="buttonContainer">
            <button className="submitBtn" onClick={submitHandler}>
              {loading ? loader(loading) : "Get Started Now"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePassword;

// let data = {
// 	leadId: userData?.leadId,
//    leadOrganisation: orgName,
//    leadPassword: confirmPassword,
//    leadSubdomainCode: subDomain,
//    captcha: captcha,
//    journeyIndex: 9,
//  };

//  let ontherData = {
// 	 name:userData?.firstName + " " + userData?.lastName,
// 	 firstName:userData?.firstName ,
// 	 lastName:userData?.lastName,
// 	 email:userData?.email,
// 	 password:confirmPassword,
//    //   recaptcha: captcha,
// 	 orgName:orgName,
// 	 orgCode:subDomain,
// 	 domainName: "localhost",
// 	 orgJoinId: null,
// 	 source: "Web",
// 	 deviceId: "localhost",
// 	 mobileNumber: "",
// 	 countryCode: "",
// 	 signupOption: "email",
// 	 subDomainCode: "cloud",
// 	 domainUrl: "localhost",
// 	 RegNo: 20001,
// 	 authenticationType: "Default",
// 	 solutionId: null,
// 	 profile:  null,
// 	 personProfilePicture:  null,
// 	 socialConnectionName:  null
// 	 }

// Promise.all([
//    await createPasswordService(data),
//    axios.post('http://172.16.114.147:1337/onboardingSignup',ontherData)
// ]).then((response)=>{
//    console.log(response);

// }).catch((error)=>{
//    console.log(error);
// })
