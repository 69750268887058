import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import vwLogo from "../../assets/images/vw-logo.png";
import { mobileOtpVerifyService } from "../../services/otpVerifyApiServices";
import { resendMobileOtpService } from "../../services/resendOtpApiServices";
import backArrow from "../../assets/images/arrow.png";
import { loader } from "../../utilies/Utilies";

const OtpVerify = () => {
  const navigate = useNavigate();

  const userData = useSelector((state) => state.userData);
  console.log("state in mobele verify:", userData);

  const [otp, setOtp] = useState(null);
  const [errorMsg, setErrorMsg] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState();
  const [color, setColor] = useState();
  const [counter, setCounter] = useState(60);
  const [loading, setLoading] = useState(false);

  const changeHandler = (value) => {
    console.log("otp value", value.length);
    setOtp(value);
  };

  const submitHandler = async () => {
    try {
      if (formValidation()) {
        setLoading(true);
        let data = {
          leadId: userData?.leadId,
          leadMobile: userData?.mobile,
          leadOtp: Number(otp),
          journeyIndex: 4,
        };
        const response = await mobileOtpVerifyService(data);
        console.log("mobile response:", response);
        if (response?.status === "success") {
          setLoading(false);
          if (response?.data?.isOtpCorrect) {
            navigate("/mobileOtpSuccess");
          } else {
            setLoading(false);
            toast.error(response?.data?.message);
          }
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resendRequest = async () => {
    try {
      let data = {
        leadId: userData?.leadId,
        leadMobile: userData?.mobile,
      };

      const response = await resendMobileOtpService(data);
      // console.log("resend mobile otp", response);
      if (
        response?.status === "success" &&
        response?.data?.statusCode === 200 &&
        response?.data?.dbResponse?.isMobileOtpSend
      ) {
        // toast.success("OTP Resended Succesfully");
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formValidation = () => {
    let isValid = true;
    if (otp?.length !== 4) {
      isValid = false;
      setErrorMsg(true);
    }
    return isValid;
  };

  useEffect(() => {
    {
      otp?.length == 4 ? setBackgroundColor("#2971D9") : setBackgroundColor();
    }
    {
      otp?.length == 4 ? setColor("white") : setColor();
    }
    if (otp?.length == 4) {
      document.getElementById("verifyBtn").focus();
    }
  }, [otp]);

  useEffect(() => {
    const timer =
      counter > 0 &&
      setInterval(() => {
        setCounter(counter - 1);
      }, 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <div>
      <div className="vwContainer">
        <div className="vwHeader">
          <div className="vwLogo">
            <img src={vwLogo} width="200px" alt="voltuswave logo" srcSet="" />
          </div>
        </div>
        <div className="vwContent">
          <div className="vwOTPHeadText">
            <strong>Confirm OTP</strong>
            <br />
            <small className="text">
              Enter OTP just sent to {userData?.code + " " + userData?.mobile}
            </small>
          </div>
          <div className="row">
            <div className="otpContainer">
              <OtpInput
                value={otp}
                onChange={changeHandler}
                numInputs={4}
                separator={<span></span>}
                inputStyle="inputOtp"
                isInputNum={true}
                shouldAutoFocus={true}
              />
              <div>
                {errorMsg && otp?.length !== 4 && (
                  <span className="errorMsg">OTP is required</span>
                )}
              </div>
            </div>
          </div>
          <div className="buttonContainer">
            <button
              id="verifyBtn"
              className="verifyBtn"
              style={{ background: backgroundColor, color: color }}
              // onClick={submitHandler}
              onFocus={submitHandler}
            >
              {loading ? loader(loading) : "verify"}
            </button>
            <div className="resendContaier">
              <span style={{ color: "#888888" }}>
                If you didn't receive a code!{" "}
                {counter ? (
                  <span className="counterText">
                    Resend In <span className="counter">[00:{counter}]</span>
                  </span>
                ) : (
                  <a onClick={resendRequest}>Resend OTP</a>
                )}
              </span>
            </div>
            <Link className="backBtn" to="/mobileSignup">
              <img
                src={backArrow}
                srcSet=""
                style={{ height: 15 }}
                className="backArrow"
              />{" "}
              Back
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpVerify;
