import axios from "axios";
import React, { useState, useEffect, useDebugValue } from "react";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { json, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import vwLogo from "../../assets/images/vw-logo.png";
import { mobile } from "../../features/userData/userDataSlice";
import { MobileSignupService } from "../../services/signupApiService";
import {
  getGeoApiLocation,
  loader,
  onkeyPressHandler,
} from "../../utilies/Utilies";

const SignUp = () => {
  const navigate = useNavigate();
  const [type, setType] = useState("mobile");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [countryCode, setCountryCode] = useState();
  const [errorMsg, setErrorMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);
  const [activeId, setActiveId] = useState("submitBtn");
  console.log("state:", userData);
  // console.log("dispatch:", dispatch);

  const getDetails = () => {
    try {
      if (userData) {
        setUserFirstName(userData?.firstName);
        setUserLastName(userData?.lastName);
        setUserEmail(userData?.email);
        setUserMobile(userData?.mobile);
        setCountryCode(userData?.code);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitHandler = async () => {
    try {
      if (formvalidations()) {
        setLoading(true);

        let data = {
          leadId: userData?.leadId,
          leadMobile: userMobile,
          countryCode: countryCode,
          journeyIndex: 3,
        };

        const response = await MobileSignupService(data);
        // console.log("mobile signup responce", response);
        if (
          response?.status === "success" &&
          response?.data?.mobileOtpGenerated &&
          response?.data?.dbResponse?.isMobileOtpSend
        ) {
          setLoading(false);
          dispatch(mobile(response?.data?.dbResponse?.mobile));
          navigate("/mobileOtpVerify");
        } else {
          setLoading(false);
          toast.error("something went wrong");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("country code:", countryCode);

  useEffect(() => {
    getDetails();
    getGeoApiLocation(setCountryCode, type);
  }, [setCountryCode]);

  let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const formvalidations = () => {
    let isValid = true;

    if (!userFirstName) {
      isValid = false;
      setErrorMsg(true);
    }

    if (!userEmail.match(regex)) {
      isValid = false;
      setErrorMsg(true);
    }
    if (!userMobile) {
      isValid = false;
      setErrorMsg(true);
    }
    return isValid;
  };

  return (
    <div>
      <div className="vwContainer">
        <div className="vwHeader">
          <div className="vwLogo">
            <img src={vwLogo} width="200px" alt="voltuswave logo" srcSet="" />
          </div>
        </div>
        <div className="vwContent">
          <div className="vwSignUpHeadText">
            <strong>Sign Up</strong>
            <br />
            <small className="text">
              Get started today by entering a few details
            </small>
          </div>
          <div className="row inputField">
            <div className="col">
              <label>First Name * </label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  value={userFirstName}
                  onChange={(e) => {
                    setUserFirstName(e.target.value);
                  }}
                  name="firstName"
                  disabled
                />
              </div>
              <div>
                {errorMsg && !userFirstName && (
                  <span className="errorMsg">First name is required</span>
                )}
              </div>
            </div>
          </div>
          <div className="row inputField">
            <div className="col">
              <label>Last Name</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  value={userLastName}
                  onChange={(e) => {
                    setUserLastName(e.target.value);
                  }}
                  name="lastName"
                  disabled
                />
              </div>
              {/* <div>
                                {errorMsg && !userDetails.lastName && <span className='errorMsg'>last name is required</span>}
                            </div> */}
            </div>
          </div>
          <div className="row inputField">
            <div className="col">
              <label>Email *</label>
              <div>
                <input
                  type="email"
                  className="form-control"
                  value={userEmail}
                  onChange={(e) => {
                    setUserEmail(e.target.value);
                  }}
                  name="email"
                  disabled
                />
                <div>
                  {(errorMsg && !userEmail && (
                    <span className="errorMsg">Email is required</span>
                  )) ||
                    (errorMsg && !userEmail.match(regex) && (
                      <span className="errorMsg">Please enter valid email</span>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row mobileContainer">
            <div className="col">
              <label>Mobile Number *</label>
              <div className="mobileField">
                <PhoneInput
                  international
                  defaultCountry={countryCode}
                  // value={countryCode}
                  // onChange={setCountryCode}
                  disabled
                />
                <input
                  type="number"
                  max={"10"}
                  className="mobileNumber"
                  value={userMobile}
                  onChange={(e) => {
                    setUserMobile(e.target.value.slice(0, 10));
                  }}
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  onKeyPress={(event) => {
                    onkeyPressHandler(event, activeId);
                  }}
                />
              </div>
              <div>
                {errorMsg && !userMobile && (
                  <span className="errorMsg">Mobile number is required</span>
                )}
              </div>
            </div>
          </div>
          <div className="buttonContainer">
            <button
              id="submitBtn"
              className="submitBtn"
              onFocus={submitHandler}
            >
              {loading ? loader(loading) : "continue"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
