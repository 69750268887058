import React, { useState } from "react";
import { Modal } from "antd";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import EmailSignUp from "./pages/emailVerfy/EmailSignup";
import EmailOtpVerify from "./pages/emailVerfy/EmailOtpVerify";
import EmailOtpSuccess from "./pages/emailVerfy/EmailOtpSuccess";
import MobileSignUp from "./pages/mobileVerify/MobileSignUp";
import MobileOtpVerify from "./pages/mobileVerify/MobileOtpVerify";
import MobileOtpSuccess from "./pages/mobileVerify/MobileOtpSuccess";
import Details from "./pages/completeDetails/Details";
import TeamSize from "./pages/completeDetails/TeamSize";
import CreatePassword from "./pages/completeDetails/CreatePassword";
import "react-phone-number-input/style.css";
import "react-toastify/dist/ReactToastify.css";
import "./styles/App.scss";
import { ToastContainer } from "react-toastify";

function App() {
  const [modalOpen, setModalOpen] = useState(true);

  return (
    <div className="App">
      <ToastContainer position="top-center" autoClose={2000} />
      <Modal
        open={modalOpen}
        closable={false}
        footer={null}
        width="800px"
        className="modal"
      >
        <Router>
          <Routes>
            <Route exact path="/" element={<EmailSignUp />} />
            <Route exact path="/emailOtpVerify" element={<EmailOtpVerify />} />
            <Route
              exact
              path="/emailOtpSuccess"
              element={<EmailOtpSuccess />}
            />
            <Route exact path="/mobileSignup" element={<MobileSignUp />} />
            <Route
              exact
              path="/mobileOtpVerify"
              element={<MobileOtpVerify />}
            />
            <Route
              exact
              path="/mobileOtpSuccess"
              element={<MobileOtpSuccess />}
            />
            <Route exact path="/details" element={<Details />} />
            <Route exact path="/teamStrength" element={<TeamSize />} />
            <Route exact path="/createPassword" element={<CreatePassword />} />
          </Routes>
        </Router>
      </Modal>
    </div>
  );
}

export default App;
