import React from "react";
import { useNavigate } from "react-router-dom";
import vwLogo from "../../assets/images/vw-logo.png";
import succesLogo from "../../assets/images/greenSuc.png";
import { lottieLoaders } from "../../utilies/Utilies";

const OtpSuccess = () => {
  const navigate = useNavigate();

  const submitHandler = () => {
    console.log("");
    navigate("/mobileSignup");
  };
  return (
    <div>
      <div className="vwContainer">
        <div className="vwHeader">
          <div className="vwLogo">
            <img src={vwLogo} width="200px" alt="voltuswave logo" srcSet="" />
          </div>
        </div>
        <div className="vwContent">
          <div className="vwOTPSuccessHead">
            <strong>Email OTP verified successfully</strong>
            <br />
          </div>
          <div className="imgContainer">
            {/* <img src={succesLogo} alt="succesfull logo" srcSet="" /> */}
            {lottieLoaders()}
          </div>
          <div className="buttonContainer">
            <button className="submitBtn" onClick={submitHandler}>
              continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpSuccess;
