import { Spin } from "antd";
import axios from "axios";
import Lottie from "lottie-react";
import successLoader from "../assets/animations/loading-success.json";

export const loader = (loading) => {
  return <Spin spinning={loading} className="loader" />;
};

export const lottieLoaders = () => {
  return (
    <Lottie
      animationData={successLoader}
      style={{ height: "250px" }}
      autoPlay={false}
      loop={false}
    />
  );
};

// gettig location details through geolocation api:
export const getGeoApiLocation = async (setCountryCode, type) => {
  try {
    const response = await axios.get(
      "https://ipinfo.io/json?token=98c5bd6190d031"
    );
    // console.log("geo location Api response :", response);
    if (response?.data) {
      setCountryCode(response?.data?.country);
      // if(type === "email"){
      //   setCountryCode(response?.data?.country)
      // }else{
      //   if (response?.data?.country === "IN") {
      //     setCountryCode("+91")
      //   }
    }
  } catch (error) {
    console.log(error);
  }
};

export const onkeyPressHandler = (event, id) => {
  console.log("event:", event, id);
  if (event.key === "Enter") {
    document.getElementById(id).focus();
  }
};
