import React, { useEffect, useState } from "react";
import { json, Link, useLocation, useNavigate } from "react-router-dom";
import vwLogo from "../../assets/images/vw-logo.png";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { EmailSignupService } from "../../services/signupApiService";
import {
  email,
  firstName,
  lastName,
  leadId,
} from "../../features/userData/userDataSlice";
import { toast } from "react-toastify";
import {
  getGeoApiLocation,
  loader,
  onkeyPressHandler,
} from "../../utilies/Utilies";
import axios from "axios";

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);
  console.log(userData);

  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState();
  const [type, setType] = useState("email");
  const [isEmailExist, setIsEmailExist] = useState(null);
  const [activeId, setActiveId] = useState("submitBtn");

  const submitHandler = async () => {
    try {
      if (formValidation() && !isEmailExist) {
        setLoading(true);
        let data = {
          firstName: userFirstName,
          lastName: userLastName,
          emailId: userEmail,
          campaignId: 68576,
          sourceId: 1,
          journeyIndex: 1,
          countryCode: countryCode,
        };

        const response = await EmailSignupService(data);
        console.log("email signup response", response);
        if (
          response?.status === "success" &&
          response?.data?.emailOtpGenerated &&
          response?.data?.data?.isEmailOtpSend
        ) {
          setLoading(false);
          dispatch(firstName(response?.data?.data?.firstName));
          dispatch(lastName(response?.data?.data?.lastName));
          dispatch(leadId(response?.data?.data?.leadId));
          dispatch(email(response?.data?.data?.leadEmail));
          navigate("/emailOtpVerify");
        } else {
          setLoading(false);
          toast.error("something went wrong");
        }
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const emailChecking = () => {
    if (userEmail.match(regex)) {
      try {
        axios
          .get(
            `https://cloud.voltusone.com/isEmailExists?email=${userEmail}`
            //  axios.get(`http://172.16.114.197:1337/isEmailExists?email=${userEmail}`,
            //   {headers: {"Content-Type": "application/json"}}
          )
          .then((response) => {
            console.log("email exist response", response);
            if (
              response?.status === 200 &&
              response?.data?.available === false
            ) {
              setIsEmailExist(true);
            } else {
              setIsEmailExist(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setIsEmailExist(false);
          });
      } catch (error) {}
    }
  };

  // console.log("loader:",loading);
  const getDetails = () => {
    if (userData) {
      setUserFirstName(userData?.firstName);
      setUserLastName(userData?.lastName);
      setUserEmail(userData?.email);
    }
  };
  // console.log('country code:',countryCode);
  useEffect(() => {
    getDetails();
    getGeoApiLocation(setCountryCode, type);
  }, [userData]);

  useEffect(() => {
    if (userEmail === "" && userEmail.length <= 0) {
      setIsEmailExist(false);
    }

    if (userEmail.match(regex)) {
      setTimeout(() => {
        emailChecking();
      }, 1000);
    }
  }, [userEmail]);

  let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  let regName = /^[a-zA-Z0-9 ]+$/;

  const formValidation = () => {
    let isValid = true;

    if (!userFirstName.match(regName)) {
      isValid = false;
      setErrorMsg(true);
    }
    if (!userLastName.match(regName)) {
      isValid = false;
      setErrorMsg(true);
    }
    if (!userEmail.match(regex)) {
      isValid = false;
      setErrorMsg(true);
    }
    return isValid;
  };

  useEffect(() => {}, [userEmail]);

  return (
    <div>
      <div className="vwContainer">
        <div className="vwHeader">
          <div className="vwLogo">
            <img src={vwLogo} width="200px" alt="voltuswave logo" srcSet="" />
          </div>
        </div>
        <div className="vwContent">
          <div className="vwSignUpHeadText">
            <strong>Sign Up</strong>
            <br />
            <small className="text">
              Get started today by entering a few details
            </small>
          </div>
          <div className="row inputField">
            <div className="col">
              <label>First Name *</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  value={userFirstName}
                  onChange={(e) => {
                    setUserFirstName(e.target.value);
                  }}
                />
                <div>
                  {(errorMsg && !userFirstName && (
                    <span className="errorMsg">First name is required</span>
                  )) ||
                    (errorMsg && !userFirstName.match(regName) && (
                      <span className="errorMsg">valid name is required</span>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row inputField">
            <div className="col">
              <label>Last Name</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  value={userLastName}
                  onChange={(e) => {
                    setUserLastName(e.target.value);
                  }}
                />
                <div>
                  {userLastName?.length > 0 &&
                    errorMsg &&
                    !userLastName?.match(regName) && (
                      <span className="errorMsg">valid name is required</span>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className="row inputField">
            <div className="col">
              <label>Email *</label>
              <div>
                <input
                  type="email"
                  className="form-control"
                  value={userEmail}
                  onChange={(e) => {
                    setUserEmail(e.target.value);
                  }}
                  // onBlur={emailChecking}
                  onKeyPress={(event) => {
                    onkeyPressHandler(event, activeId);
                  }}
                />
                <div>
                  {(errorMsg && !userEmail && (
                    <span className="errorMsg">Email is required</span>
                  )) ||
                    (errorMsg && !userEmail.match(regex) && (
                      <span className="errorMsg">Please enter valid email</span>
                    ))}
                  {isEmailExist ? (
                    <span className="linkMsg">
                      Welcome you are already part of Voltuswave. Please{" "}
                      <a
                        href={`https://cloud${process.env.REACT_APP_ACCOUNT_SUB_DOMAIN}/accountlogin.html`}
                      >
                        Login
                      </a>
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="buttonContainer">
            <button
              id="submitBtn"
              className="submitBtn"
              onFocus={submitHandler}
              disabled={isEmailExist}
              style={{
                backgroundColor: isEmailExist ? "#7bb0fa" : "  ",
                cursor: isEmailExist ? "not-allowed" : "pointer",
              }}
            >
              {loading ? loader(loading) : "continue"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
